import { useEffect, useState } from "react";
import larrow from "../../images/arrow-left.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { ThemeProvider } from "@mui/material/styles";
import {
  Grid,
  Container,
  Stack,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import axios from "../../utils/axiosconfig";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import {
  FormProvider,
  RHFTextField,
  RHFSelect,
} from "../../components/hook-form";
import useSettings from "../../hooks/useSettings";
import { countries } from "../../utils/constants/_countries";

export default function NewCity() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { pathname } = useLocation();
  const isEdit = pathname.includes("edit");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [currency, setCurrency] = useState("INR");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const methods = useForm({
    // resolver: yupResolver(NewUserSchema),
    // defaultValues,
  });

  useEffect(() => {
    if (id) {
      getCity();
    }
  }, []);

  const getCity = () => {
    setLoading(true);
    axios
      .get(`cities/get/${id}`)
      .then((res) => {
        console.log("testing301", res.data.result);
        const currentCity = res?.data?.result;
        if (currentCity) {
          setCountry(currentCity.country);
          setState(currentCity.state);
          setCity(currentCity.city);
          setCurrency(currentCity.currency);
          setLatitude(currentCity?.location?._latitude);
          setLongitude(currentCity?.location?._longitude);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleOnClick = () => {
    navigate(-1);
  };

  const onSubmit = () => {
    setLoading(true);
    if (!isEdit) {
      const payload = {};
      payload.city = city;
      payload.country = country;
      payload.state = state;
      payload.latitude = parseFloat(latitude);
      payload.longitude = parseFloat(longitude);
      payload.currency = currency;
      axios
        .post("/cities/add", payload)
        .then((res) => {
          console.log(res);
          setLoading(false);
          navigate(PATH_DASHBOARD.cities.cities);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const payload = {};
      payload.city = city;
      payload.country = country;
      payload.state = state;
      payload.latitude = parseFloat(latitude);
      payload.longitude = parseFloat(longitude);
      payload.currency = currency;

      axios
        .put(`/cities/update/${id}`, payload)
        .then((res) => {
          console.log(res);
          setLoading(false);
          navigate(PATH_DASHBOARD.cities.cities);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <ThemeProvider theme={{}}>
        <div
          style={{
            position: "absolute",
            width: "80%",
            zIndex: 1000,
            marginTop: 350,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading && <CircularProgress />}
        </div>
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box sx={{ width: "100%" }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Box
                className="px-0"
                sx={{ padding: "24px", paddingBottom: "0" }}
                style={{ display: "flex", borderBottom: "1px solid #E04E2C" }}
              >
                <img
                  alt="arrow icon"
                  src={larrow}
                  onClick={handleOnClick}
                  height={20}
                  style={{
                    marginRight: "10px",
                    marginTop: "7px",
                    width: "25px",
                    height: "25px",
                    cursor: "pointer",
                  }}
                />
                {!isEdit ? (
                  <Typography
                    sx={{
                      color: "#030303",
                      fontSize: "24px",
                      fontWeight: "300",
                    }}
                  >
                    CREATE NEW CITY
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      color: "#030303",
                      fontSize: "24px",
                      fontWeight: "300",
                    }}
                  >
                    EDIT CITY
                  </Typography>
                )}
              </Box>

              <Box
                className="px-0"
                sx={{ padding: "24px", paddingBottom: "0", paddingTop: "0" }}
              >
                <HeaderBreadcrumbs
                  heading={""}
                  links={[
                    { name: "Cities", href: PATH_DASHBOARD.cities.cities },
                    {
                      name: "Add new",
                      href: PATH_DASHBOARD.cities.root,
                    },
                  ]}
                />
              </Box>

              <Grid
                container
                className="px-0"
                sx={{ paddingLeft: "34px", paddingRight: "34px" }}
              >
                <Grid item xs={12} md={6} lg={6}>
                  <Box
                    sx={{
                      width: "80vw",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        background: "white",
                        padding: "40px",
                        display: "flex",
                      }}
                    >
                      <Box style={{ marginRight: "30px" }}>
                        <Typography sx={{ mb: "20px", fontWeight: "bold" }}>
                          City Details
                        </Typography>
                        <Box
                          sx={{
                            display: "grid",
                            columnGap: 4,
                            rowGap: 3,
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              sm: "repeat(2, 1fr)",
                            },
                          }}
                        >
                          <Box sx={{}}>
                            <Typography className="para_label">
                              Country
                            </Typography>
                            <RHFSelect
                              sx={{ width: "100%" }}
                              name="country"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                              required
                            >
                              <option value="" />
                              {countries.map((option) => (
                                <option key={option.id} value={option.label}>
                                  {option.label}
                                </option>
                              ))}
                            </RHFSelect>
                          </Box>

                          <Box sx={{}}>
                            <Typography className="para_label">
                              State
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              name="state"
                              type="text"
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                              required
                            />
                          </Box>

                          <Box sx={{}}>
                            <Typography className="para_label">City</Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              name="city"
                              type="text"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              required
                            />
                          </Box>

                          <Box sx={{}}>
                            <Typography className="para_label">
                              Currency
                            </Typography>
                            <RHFSelect
                              sx={{ width: "100%" }}
                              name="title"
                              value={currency}
                              onChange={(e) => setCurrency(e.target.value)}
                              className="custom-calendar"
                              calendarClassName="rasta-stripes"
                              required
                            >
                              <option value="INR" selected>
                                ₹ INR
                              </option>
                              <option value="Dollar">$ Dollar</option>
                            </RHFSelect>
                          </Box>
                          <Box sx={{}}>
                            <Typography className="para_label">
                              Latitude
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              name="title"
                              type="number"
                              value={latitude}
                              onChange={(e) => setLatitude(e.target.value)}
                              required
                            />
                          </Box>
                          <Box sx={{}}>
                            <Typography className="para_label">
                              Longitude
                            </Typography>
                            <RHFTextField
                              sx={{ width: "100%" }}
                              name="title"
                              type="number"
                              value={longitude}
                              onChange={(e) => setLongitude(e.target.value)}
                              required
                            />
                          </Box>
                        </Box>

                        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                          <LoadingButton
                            sx={{ padding: "15px 0", borderRadius: "5px" }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            size="large"
                            loading={isSubmitting}
                          >
                            {!isEdit ? "ADD" : "Save Changes"}
                          </LoadingButton>
                        </Stack>
                      </Box>

                      <Box
                        sx={{
                          marginLeft: "30px",
                          marginRight: "30px",
                        }}
                      >
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </FormProvider>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
