import { useEffect, useState, React } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import useSettings from "../../hooks/useSettings";
import axios from "../../utils/axiosconfig";
import { useSelector } from "react-redux";
import Page from "../../components/Page";
import cadmin from "../../images/clockadmin.svg";
import psb from "../../images/psb.svg";
import { useSnackbar } from "notistack";
import PackageTypes from "./packageTypes";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function RateList() {
  const [id, setId] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const [contentEditable, setContentEditable] = useState(false);
  const getLoginUser = useSelector((state) => state.getLoginUser.data);
  const [value, setValue] = useState(0);
  const cityId = localStorage.getItem("manager_city");
  const [data, setData] = useState({
    baseKm: 0,
    basicWaitingDuration: 0,
    waitingRate: 0,
    fragilePerPointRate: 0,
    nonFragilePerPointRate: 0,
    fragilePerPointPay: 0,
    nonFragilePerPointPay: 0,
    distanceRate: 0,
    distancePay: 0,
    durationRate: 0,
    durationPay: 0,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (getLoginUser?.role === "ADMIN_ROLE") setContentEditable(true);
    else setContentEditable(false);
  }, [getLoginUser]);
  useEffect(() => {
    getRate();
  }, []);

  const getRate = () => {
    axios
      .get(`/rates/get/current/${cityId}`)
      .then((res) => {
        const obj = res.data.result;
        setId(obj?.id);
        setData({
          baseKm: obj?.baseKm || 0,
          fragilePerPointRate: obj?.fragilePerPointRate || 0,
          nonFragilePerPointRate: obj?.nonFragilePerPointRate || 0,
          fragilePerPointPay: obj?.fragilePerPointPay || 0,
          nonFragilePerPointPay: obj?.nonFragilePerPointPay || 0,
          distanceRate: obj?.distanceRate || 0,
          durationRate: obj?.durationRate || 0,
          basicWaitingDuration: obj?.basicWaitingDuration || 0,
          waitingRate: obj?.waitingRate || 0,
          distancePay: obj?.distancePay || 0,
          durationPay: obj?.durationPay || 0,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handleSaveRateChanges = () => {
    axios
      .put(`rates/update/${id}`, data)
      .then((res) => {
        // enqueueSnackbar("Rates Updated Successfully");
        alert("Rates Updated Successfully.");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        return enqueueSnackbar("Error While Updating the Rates", {
          variant: "error",
        });
      });
  };

  return (
    <ThemeProvider theme={{}}>
      <Page title="Rates">
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box sx={{ width: "100%" }}>
            <Box className="px-0" sx={{ padding: "24px", paddingBottom: "0" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  sx={{ fontSize: "32px", fontWeight: 300 }}
                  label="RATES"
                  {...a11yProps(0)}
                />
                {cityId && (
                  <Tab
                    sx={{ fontSize: "32px", fontWeight: 300 }}
                    label="PACKAGES"
                    {...a11yProps(1)}
                  />
                )}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} className="tabPanel">
              {getLoginUser?.role === "ADMIN_ROLE" && (
                <Button
                  className="createBtn"
                  variant="contained"
                  style={{ background: "#E04E2C" }}
                  onClick={() => handleSaveRateChanges()}
                >
                  Save Changes
                </Button>
              )}

              <Box sx={{ padding: "0", marginBottom: "25px" }}>
                <Typography
                  sx={{
                    color: "#030303",
                    fontSize: "24px",
                    fontWeight: "300",
                    borderBottom: "1px solid #E04E2C",
                  }}
                >
                  BASE  
                </Typography>
              </Box>
              <Grid container spacing={3} style={{ marginBottom: "30px" }}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  style={{ position: "relative" }}
                >
                  <Box
                    style={{
                      boxShadow:
                        "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
                      backgroundColor: "white",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={psb} alt="rupees" style={{ height: "130px" }} />
                      <Box>
                        <Box
                          style={{
                            display: "flex",
                            float: "right",
                            marginRight: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "50px",
                              fontWeight: "700",
                            }}
                            contentEditable={contentEditable}
                            onBlur={(e) => {
                              return setData({
                                ...data,
                                baseKm: parseFloat(e.target.textContent),
                              });
                            }}
                          >
                            {data?.baseKm || 0}
                          </Typography>
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "16px",
                              fontWeight: "400",
                              marginTop: "13px",
                            }}
                          >
                            KM
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            color: "#030303",
                            fontSize: "21px",
                            fontWeight: "700",
                          }}
                        >
                          BASE KM
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  style={{ position: "relative" }}
                >
                  <Box
                    style={{
                      boxShadow:
                        "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
                      backgroundColor: "white",
                      padding: "0 15px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#030303",
                          fontSize: "21px",
                          fontWeight: "700",
                        }}
                      >
                        Free Waiting
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          float: "right",
                          marginRight: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#E3552E",
                            fontSize: "50px",
                            fontWeight: "700",
                          }}
                          contentEditable={contentEditable}
                          onBlur={(e) => {
                            return setData({
                              ...data,
                              basicWaitingDuration: parseFloat(
                                e.target.textContent
                              ),
                            });
                          }}
                        >
                          {data?.basicWaitingDuration || 0}
                        </Typography>
                        <Typography
                          style={{
                            color: "#E3552E",
                            fontSize: "16px",
                            fontWeight: "400",
                            marginTop: "13px",
                          }}
                        >
                          MIN
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#030303",
                          fontSize: "21px",
                          fontWeight: "700",
                        }}
                      >
                        Wait/Min
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          float: "right",
                          marginRight: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "36px",
                            fontWeight: "700",
                          }}
                          contentEditable={contentEditable}
                          onBlur={(e) => {
                            return setData({
                              ...data,
                              waitingRate: parseFloat(e.target.textContent),
                            });
                          }}
                        >
                          {data?.waitingRate || 0}
                        </Typography>
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "16px",
                            fontWeight: "400",
                            marginTop: "13px",
                          }}
                        >
                          INR
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ padding: "0", marginBottom: "25px" }}>
                <Typography
                  sx={{
                    color: "#030303",
                    fontSize: "24px",
                    fontWeight: "300",
                    borderBottom: "1px solid #E04E2C",
                  }}
                >
                  RATE CARDS
                </Typography>
              </Box>
              <Grid container spacing={3} style={{ marginBottom: "30px" }}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  style={{ position: "relative" }}
                >
                  <Box
                    style={{
                      boxShadow:
                        "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
                      backgroundColor: "white",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={psb} alt="rupees" style={{ height: "130px" }} />
                      <Box>
                        <Box
                          style={{
                            display: "flex",
                            float: "right",
                            marginRight: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "50px",
                              fontWeight: "700",
                            }}
                            contentEditable={contentEditable}
                            onBlur={(e) => {
                              return setData({
                                ...data,
                                distanceRate: parseFloat(e.target.textContent),
                              });
                            }}
                          >
                            {data?.distanceRate || 0}
                          </Typography>
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "16px",
                              fontWeight: "400",
                              marginTop: "13px",
                            }}
                          >
                            INR
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            color: "#030303",
                            fontSize: "21px",
                            fontWeight: "700",
                          }}
                        >
                          FARE/Km
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  style={{ position: "relative" }}
                >
                  <Box
                    style={{
                      boxShadow:
                        "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
                      backgroundColor: "white",
                      padding: "0 15px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#030303",
                          fontSize: "21px",
                          fontWeight: "700",
                        }}
                      >
                        Fragile/Point
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          float: "right",
                          marginRight: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#E3552E",
                            fontSize: "50px",
                            fontWeight: "700",
                          }}
                          contentEditable={contentEditable}
                          onBlur={(e) => {
                            return setData({
                              ...data,
                              fragilePerPointRate: parseFloat(
                                e.target.textContent
                              ),
                            });
                          }}
                        >
                          {data?.fragilePerPointRate || 0}
                        </Typography>
                        <Typography
                          style={{
                            color: "#E3552E",
                            fontSize: "16px",
                            fontWeight: "400",
                            marginTop: "13px",
                          }}
                        >
                          INR
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#030303",
                          fontSize: "21px",
                          fontWeight: "700",
                        }}
                      >
                        Non-Fragile/Point
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          float: "right",
                          marginRight: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "36px",
                            fontWeight: "700",
                          }}
                          contentEditable={contentEditable}
                          onBlur={(e) => {
                            return setData({
                              ...data,
                              nonFragilePerPointRate: parseFloat(
                                e.target.textContent
                              ),
                            });
                          }}
                        >
                          {data?.nonFragilePerPointRate || 0}
                        </Typography>
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "16px",
                            fontWeight: "400",
                            marginTop: "13px",
                          }}
                        >
                          INR
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  style={{ position: "relative" }}
                >
                  <Box
                    style={{
                      boxShadow:
                        "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
                      backgroundColor: "white",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <img
                        src={cadmin}
                        alt="rupees"
                        style={{ height: "130px" }}
                      />
                      <Box>
                        <Box
                          style={{
                            display: "flex",
                            float: "right",
                            marginRight: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "50px",
                              fontWeight: "700",
                            }}
                            contentEditable={contentEditable}
                            onBlur={(e) => {
                              return setData({
                                ...data,
                                durationRate: parseFloat(e.target.textContent),
                              });
                            }}
                          >
                            {data?.durationRate || 0}
                          </Typography>
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "16px",
                              fontWeight: "400",
                              marginTop: "13px",
                            }}
                          >
                            INR
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            color: "#030303",
                            fontSize: "21px",
                            fontWeight: "700",
                          }}
                        >
                          FARE/Min
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Box sx={{ padding: "0", marginBottom: "25px" }}>
                <Typography
                  sx={{
                    color: "#030303",
                    fontSize: "24px",
                    fontWeight: "300",
                    borderBottom: "1px solid #E04E2C",
                  }}
                >
                  PAY CARDS
                </Typography>
              </Box>
              <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  style={{ position: "relative" }}
                >
                  <Box
                    style={{
                      boxShadow:
                        "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
                      backgroundColor: "white",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={psb} alt="rupees" style={{ height: "130px" }} />
                      <Box>
                        <Box
                          style={{
                            display: "flex",
                            float: "right",
                            marginRight: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "50px",
                              fontWeight: "700",
                            }}
                            contentEditable={contentEditable}
                            onBlur={(e) => {
                              return setData({
                                ...data,
                                distancePay: parseFloat(e.target.textContent),
                              });
                            }}
                          >
                            {data?.distancePay || 0}
                          </Typography>
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "16px",
                              fontWeight: "400",
                              marginTop: "13px",
                            }}
                          >
                            INR
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            color: "#030303",
                            fontSize: "21px",
                            fontWeight: "700",
                          }}
                        >
                          PAY/Km
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  style={{ position: "relative" }}
                >
                  <Box
                    style={{
                      boxShadow:
                        "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
                      backgroundColor: "white",
                      padding: "0 15px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#030303",
                          fontSize: "21px",
                          fontWeight: "700",
                        }}
                      >
                        Fragile/Point
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          float: "right",
                          marginRight: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#E3552E",
                            fontSize: "50px",
                            fontWeight: "700",
                          }}
                          contentEditable={contentEditable}
                          onBlur={(e) => {
                            return setData({
                              ...data,
                              fragilePerPointPay: parseFloat(
                                e.target.textContent
                              ),
                            });
                          }}
                        >
                          {data?.fragilePerPointPay || 0}
                        </Typography>
                        <Typography
                          style={{
                            color: "#E3552E",
                            fontSize: "16px",
                            fontWeight: "400",
                            marginTop: "13px",
                          }}
                        >
                          INR
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#030303",
                          fontSize: "21px",
                          fontWeight: "700",
                        }}
                      >
                        Non-Fragile/Point
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          float: "right",
                          marginRight: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "36px",
                            fontWeight: "700",
                          }}
                          contentEditable={contentEditable}
                          onBlur={(e) => {
                            return setData({
                              ...data,
                              nonFragilePerPointPay: parseFloat(
                                e.target.textContent
                              ),
                            });
                          }}
                        >
                          {data?.nonFragilePerPointPay || 0}
                        </Typography>
                        <Typography
                          style={{
                            color: "#002236",
                            fontSize: "16px",
                            fontWeight: "400",
                            marginTop: "13px",
                          }}
                        >
                          INR
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  style={{ position: "relative" }}
                >
                  <Box
                    style={{
                      boxShadow:
                        "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
                      backgroundColor: "white",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <img
                        src={cadmin}
                        alt="rupees"
                        style={{ height: "130px" }}
                      />
                      <Box>
                        <Box
                          style={{
                            display: "flex",
                            float: "right",
                            marginRight: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "50px",
                              fontWeight: "700",
                            }}
                            contentEditable={contentEditable}
                            onBlur={(e) => {
                              return setData({
                                ...data,
                                durationPay: parseFloat(e.target.textContent),
                              });
                            }}
                          >
                            {data?.durationPay || 0}
                          </Typography>
                          <Typography
                            style={{
                              color: "#E3552E",
                              fontSize: "16px",
                              fontWeight: "400",
                              marginTop: "13px",
                            }}
                          >
                            INR
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            color: "#030303",
                            fontSize: "21px",
                            fontWeight: "700",
                          }}
                        >
                          PAY/Min
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </TabPanel>
            {cityId && (
              <TabPanel value={value} index={1} className="tabPanel">
                <PackageTypes />
              </TabPanel>
            )}
            <TabPanel value={value} index={2} className="tabPanel">
              <Box sx={{ padding: "0", marginBottom: "25px" }}>
                <Typography
                  sx={{
                    color: "#030303",
                    fontSize: "24px",
                    fontWeight: "300",
                    borderBottom: "1px solid #E04E2C",
                  }}
                />
              </Box>
            </TabPanel>
          </Box>
        </Container>
      </Page>
    </ThemeProvider>
  );
}
