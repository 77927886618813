import React, {  } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import m_circle from "../../images/message-circle.svg";
import SquareIcon from "@mui/icons-material/Square";
import HourglassHigh from "../../images/HourglassHigh.svg";
import TwoWheelerSharpIcon from "@mui/icons-material/TwoWheelerSharp";
import SportsMotorsportsIcon from "@mui/icons-material/SportsMotorsports";
import {
  Typography,
  Box,
  Divider,
  Chip,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import correct from "../../images/correct.png";
import done from "../../images/Done.svg";
import { useSnackbar } from "notistack";

const Destination = (props) => {
  const { loading, data, setOTPVisible, setTaskId, needOTPVerify } = props;

  const { enqueueSnackbar } = useSnackbar();
  const style = {
    textHead: {
      color: "#002236",
      fontSize: "13px",
      fontWeight: "600",
    },
  };

  const copyMobileNo = (mobileNo) => {
    navigator.clipboard
      .writeText(mobileNo)
      .then(() => {
        enqueueSnackbar("Copied to clipboard.", {
          style: { marginTop: "100px" },
        });
      })
      .catch((error) => {
        console.log("Failed to copy link:", error);
      });
  };
  const formatDistance = (distanceInMeters) => {
    let distanceInKm = distanceInMeters / 1000;

    if (distanceInKm >= 100) {
      // If distance is 100 KM or more, show only the first two digits with two decimal places
      distanceInKm = Math.floor(distanceInKm / 10); // Get the first two digits
      distanceInKm /= 10; // Adjust to get one decimal place
    }
    if (!distanceInKm) return "_._ Km";

    return `${distanceInKm.toFixed(1)} KM`;
  };

  const DestinationCard = ({ task, user }) => {
    if (!task || !user || task.order === 0) return null;
    // debugger;
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "10px",
        }}
      >
        <Box style={{ minWidth: "18px" }}>
          <Divider
            sx={{}}
            orientation="vertical"
            variant="middle"
            style={{
              backgroundColor: "rgb(128, 128, 128)",
              width: "2px",
              marginTop: "-3px",
              marginLeft: "auto",
              marginRight: "auto",
              height: "50%",
              marginBottom: "-8px",
            }}
          />
          <SquareIcon style={{ width: "18px", height: "18px" }} />
        </Box>
        <Box className="stepKm">{formatDistance(task.distance)}</Box>
        <Box
          style={{
            display: "flex",
            alignItems: "start",
            background: getBackgroundColor(task?.stage?.order),
            borderRadius: "15px",
            padding: "10px",
            marginLeft: "18px",
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            style={{ marginLeft: "10px", marginRight: "20px", width: "40px" }}
          >
            {task?.stage?.stage === "Completed" ? (
              <>
                <img
                  className="notCompletedIcon"
                  src={correct}
                  alt="Complete Task Icon"
                />
                <Typography
                  sx={{
                    marginTop: "15px",
                  }}
                  style={style.textHead}
                >
                  Done
                </Typography>
              </>
            ) : (
              <>
                <img
                  src={HourglassHigh}
                  alt="Task is not Completed Icon"
                  className="notCompletedIcon"
                />
                <Typography
                  sx={{
                    marginTop: "15px",
                  }}
                  style={style.textHead}
                >
                  {task?.otp}
                </Typography>
              </>
            )}
          </Box>
          <Box style={{ flex: "1" }}>
            {/* <Typography
              className="sameSizeP"
              style={{
                color: "#002236",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {" "}
              {user?.display_name?.replace(
                /(\w)(\w*)/g,
                (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
              ) || ""}
            </Typography> */}
            <Typography
              className=""
              style={{
                color: "#E3552E",
                fontSize: "10px",
                fontWeight: "700",
              }}
            >
              Drop
            </Typography>
            {task?.instruction?.length > 0 && (
              <Box>
                <Typography
                  className="sameSizeP"
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#9D9E9E",
                    fontFamily: "Cabin",
                  }}
                >
                  <span>
                    Instruction:{" "}
                    {task?.instruction?.replace(
                      /(\w)(\w*)/g,
                      (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
                    )}
                  </span>
                </Typography>
              </Box>
            )}

            <Typography
              className="sameSizeP"
              style={{
                color: "#002236",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {" "}
              {task?.endDest?.alias?.replace(
                /(\w)(\w*)/g,
                (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
              ) || ""}
            </Typography>
            <Typography
              className="sameSizeP"
              style={{
                color: "#9D9E9E",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {" "}
              {task?.endDest?.address?.replace(
                /(\w)(\w*)/g,
                (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
              ) || ""}
            </Typography>
            <Typography
              className="sameSizeP"
              style={{
                color: "#002236",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Phone No: {task?.endDest?.mobile}
            </Typography>
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              alignSelf: "stretch",
            }}
          >
            <Box
              style={{
                display: "flex",
                gap: "8px",
              }}
            >
              <Tooltip title={"Copy mobile number"} placement="bottom">
                <Box
                  className="icnBtns"
                  onClick={() => copyMobileNo(task?.endDest?.mobile)}
                  style={{
                    height: "35px",
                    width: "35px",
                    borderRadius: "50%",
                    background: "#E3552E",
                    cursor: "pointer",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={m_circle}
                    alt=""
                    style={{
                      position: "absolute",
                      height: "50%",
                      width: "50%",
                    }}
                  />
                </Box>
              </Tooltip>
              {needOTPVerify && task?.stage?.stage !== "Completed" && (
                <Tooltip title={"Verify OTP"} placement="bottom">
                  <Box
                    className="icnBtns"
                    key={task?._id}
                    style={{
                      height: "35px",
                      width: "35px",
                      borderRadius: "50%",
                      background: "#E3552E",
                      cursor: "pointer",
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setTaskId(task?._id);
                      setOTPVisible(true);
                    }}
                  >
                    <img
                      src={done}
                      alt=""
                      style={{
                        position: "absolute",
                        height: "65%",
                        width: "65%",
                      }}
                    />
                  </Box>
                </Tooltip>
              )}
            </Box>
            {task?.packageType?.icon && (
              <Tooltip
                title={task?.packageType?.type || "Tooltip"}
                placement="bottom"
              >
                <img
                  src={task?.packageType?.icon}
                  alt="Package Icon"
                  style={{
                    width: "40px",
                    height: "40px",
                    bottom: 10,
                  }}
                />
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    );
  };
  const TaskCard = ({ task, user }) => {
    if (!task || !user) return null;
    // debugger;
    return (
      <>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "10px",
            position: "relative",
          }}
        >
          <Box style={{ minWidth: "18px", position: "relative" }}>
            <CircleIcon
              style={{
                color: "#E3552E",
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "45%",
                transform: "translateY(-45%)",
              }}
            />
            <Divider
              sx={{}}
              orientation="vertical"
              variant="middle"
              style={{
                backgroundColor: "rgb(128, 128, 128)",
                width: "2px",
                marginTop: "-3px",
                marginLeft: "auto",
                marginRight: "auto",
                height: "120px",
              }}
            />
          </Box>
          <Box className="stepKm">{formatDistance(task.distance)}</Box>

          <Box
            style={{
              display: "flex",
              alignItems: "start",
              background: getBackgroundColor(task?.stage?.order),
              borderRadius: "15px",
              padding: "10px",
              marginLeft: "18px",
              width: "100%",
              marginBottom: "15px",
            }}
          >
            <Box
              style={{ marginLeft: "10px", marginRight: "20px", width: "40px" }}
            >
              {task?.stage?.stage === "Completed" ? (
                <>
                  <img
                    className="notCompletedIcon"
                    src={correct}
                    alt="Complete Task Icon"
                  />
                  <Typography
                    sx={{
                      marginTop: "15px",
                    }}
                    style={style.textHead}
                  >
                    Done
                  </Typography>
                </>
              ) : (
                <>
                  <img
                    src={HourglassHigh}
                    alt="Task is not Completed Icon"
                    className="notCompletedIcon"
                  />
                  <Typography
                    sx={{
                      marginTop: "15px",
                    }}
                    style={style.textHead}
                  >
                    {task?.otp}
                  </Typography>
                </>
              )}
            </Box>
            <Box style={{ flex: "1" }}>
              {/* <Typography
                className="sameSizeP"
                style={{
                  color: "#002236",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {" "}
                {user?.display_name?.replace(
                  /(\w)(\w*)/g,
                  (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
                ) || ""}
              </Typography> */}
              <Typography
                className=""
                style={{
                  color: "#E3552E",
                  fontSize: "10px",
                  fontWeight: "700",
                }}
              >
                Pickup
              </Typography>
              {task?.instruction?.length > 0 && (
                <Box>
                  <Typography
                    className="sameSizeP"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#9D9E9E",
                      fontFamily: "Cabin",
                    }}
                  >
                    <span>
                      Instruction:{" "}
                      {task?.instruction?.replace(
                        /(\w)(\w*)/g,
                        (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
                      )}
                    </span>
                  </Typography>
                </Box>
              )}

              <Typography
                className="sameSizeP"
                style={{
                  color: "#002236",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {" "}
                {task?.endDest?.alias?.replace(
                  /(\w)(\w*)/g,
                  (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
                ) || ""}
              </Typography>
              <Typography
                className="sameSizeP"
                style={{
                  color: "#9D9E9E",
                  fontSize: "12px",
                  fontWeight: "700",
                }}
              >
                {" "}
                {task?.endDest?.address?.replace(
                  /(\w)(\w*)/g,
                  (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
                ) || ""}
              </Typography>
              <Typography
                className="sameSizeP"
                style={{
                  color: "#002236",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Phone No: {task?.endDest?.mobile}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                alignSelf: "stretch",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  gap: "8px",
                }}
              >
                <Tooltip title={"Copy mobile number"} placement="bottom">
                  <Box
                    className="icnBtns"
                    onClick={() => copyMobileNo(task?.endDest?.mobile)}
                    style={{
                      height: "35px",
                      width: "35px",
                      borderRadius: "50%",
                      background: "#E3552E",
                      cursor: "pointer",
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={m_circle}
                      alt=""
                      style={{
                        position: "absolute",
                        height: "50%",
                        width: "50%",
                      }}
                    />
                  </Box>
                </Tooltip>
                {needOTPVerify && task?.stage?.stage !== "Completed" && (
                  <Tooltip title={"Verify OTP"} placement="bottom">
                    <Box
                      className="icnBtns"
                      key={task?._id}
                      style={{
                        height: "35px",
                        width: "35px",
                        borderRadius: "50%",
                        background: "#E3552E",
                        cursor: "pointer",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setTaskId(task?._id);
                        setOTPVisible(true);
                      }}
                    >
                      <img
                        src={done}
                        alt=""
                        style={{
                          position: "absolute",
                          height: "65%",
                          width: "65%",
                        }}
                      />
                    </Box>
                  </Tooltip>
                )}
              </Box>
              {task?.packageType?.icon && (
                <Tooltip
                  title={task?.packageType?.type || "Tooltip"}
                  placement="bottom"
                >
                  <img
                    src={task?.packageType?.icon}
                    alt="Package Icon"
                    style={{
                      position: "absolute",
                      width: "40px",
                      height: "40px",
                      bottom: 25,
                      right: 5,
                    }}
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
  };
  if (loading)
    return (
      <Box
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (data?.tasksList?.length === 0)
    return (
      <Typography sx={{ textAlign: "center", marginTop: "10px" }}>
        Destinations Data not found.
      </Typography>
    );
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        position: "relative",
        pr: 2,
      }}
    >
      {/* <Box
        style={{
          width: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Typography
          style={{
            transform: "rotate(-90deg)",
            fontSize: "16px",
            fontWeight: "700",
          }}
        >
          Total&nbsp;Distance:&nbsp;{data?.totalDistance?.toFixed(1)}&nbsp;km.
        </Typography>
      </Box> */}
      {/* Rider Accepted */}
      {console.log("rider", data)}
      <Box style={{ width: "100%", paddingLeft: "10px" }}>
        <Box style={{ display: "flex", marginLeft: "10px" }}>
          {data?.tasksList[0]?.distance ? (
            <>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "18px",
                }}
              >
                <CircleIcon
                  style={{
                    color: "green",
                    height: "18px",
                    width: "18px",
                    marginTop: "8px",
                  }}
                />
                <Divider
                  sx={{}}
                  orientation="vertical"
                  variant="middle"
                  style={{
                    backgroundColor: "rgb(128, 128, 128)",
                    width: "2px",
                    marginTop: "-3px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "100px",
                  }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "18px",
                }}
              >
                <Box style={{}}>
                  <Chip
                    label="Rider Accepted"
                    icon={
                      <SportsMotorsportsIcon
                        style={{ color: "#E3552E", fontSize: "16px" }}
                      />
                    }
                    style={{
                      backgroundColor: "#FCEEEA",
                      color: "#E3552E",
                      fontWeight: "500",
                      fontSize: "12px",
                      padding: "0 6px",
                    }}
                  />
                </Box>
                {data?.tasksList[0]?.order === 0 &&
                  data?.tasksList[0]?.distance && (
                    <Box style={{ marginTop: "55px" }}>
                      <Chip
                        label={`Accepted Distance: ${formatDistance(
                          data?.tasksList[0]?.distance
                        )}`}
                        icon={
                          <TwoWheelerSharpIcon
                            style={{ color: "#42A06F", fontSize: "16px" }}
                          />
                        }
                        style={{
                          backgroundColor: "#DEFFED",
                          color: "#42A06F",
                          fontWeight: "500",
                          fontSize: "12px",
                          padding: "0 6px",
                        }}
                      />
                    </Box>
                  )}
              </Box>
            </>
          ) : (
            <>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "18px",
                }}
              >
                <CircleIcon
                  style={{
                    color: "green",
                    height: "18px",
                    width: "18px",
                    marginTop: "8px",
                  }}
                />
                <Divider
                  sx={{}}
                  orientation="vertical"
                  variant="middle"
                  style={{
                    backgroundColor: "rgb(128, 128, 128)",
                    width: "2px",
                    marginTop: "-3px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "40px",
                  }}
                />
              </Box>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "18px",
                }}
              >
                <Box style={{}}>
                  <Chip
                    label="Rider Not Assigned"
                    icon={
                      <SportsMotorsportsIcon
                        style={{ color: "#E3552E", fontSize: "16px" }}
                      />
                    }
                    style={{
                      backgroundColor: "#FCEEEA",
                      color: "#E3552E",
                      fontWeight: "500",
                      fontSize: "12px",
                      padding: "0 6px",
                    }}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
        {/* Task Card */}
        {data?.tasksList?.map((task, index) => {
          if (index === data.tasksList.length - 1 && task?.order !== 0)
            return null;
          return <TaskCard key={task.id} task={task} user={data.user} />;
        })}
        {/* Destination Card */}
        <DestinationCard
          task={data?.tasksList[data?.tasksList?.length - 1]}
          user={data?.user}
        />
      </Box>
    </Box>
  );
};
const getBackgroundColor = (order) => {
  if (order === 0) return "#F9F9F9";
  else if (order === 1) return "#81c784";
  else if (order === 2) return "#ffcc80";
  else if (order === 3) return "#DEFFED";
};
export default Destination;
