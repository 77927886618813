import { useNavigate } from "react-router-dom";
import { signOut, getAuth } from "firebase/auth";
import { setLoginUser } from "src/redux/slices/GetLoginUser";
import { useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = getAuth();

  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated");
    signOut(auth)
      .then(() => {
        dispatch(setLoginUser(null));
        navigate("/auth/login");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="delIcon" onClick={handleLogout}>
        <Tooltip title="Log out" placement="bottom" sx={{zIndex:10001}}>

        <div className="delIcon-body"></div>
        </Tooltip>
      </div>
    </>
  );
}
