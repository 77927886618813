import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  Chip,
} from "@mui/material";
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
import cssStyles from "../../../utils/cssStyles";
import { HEADER } from "../../../utils/config";
import Logo from "../../../components/Logo";
import Iconify from "../../../components/Iconify";
import { IconButtonAnimate } from "../../../components/animate";
import pn from "../../../images/pickupLogo-1.png";
import NavbarDocs from "../navbar/NavbarDocs";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import axios from "../../../utils/axiosconfig";
import localStorage from "redux-persist/es/storage";

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "0 0 2px 2px #c4cdd5",
  background: "white",
  height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  zIndex: 10000,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: "",
    ...(isCollapse && {
      width: "100VW",
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    }),
    ...(verticalLayout && {
      width: "100VW",
      height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const [manager, setManager] = useState(null);
  const getLoginUser = useSelector((state) => state.getLoginUser.data);
  const managerId = window.localStorage.getItem("manager_id");
  const navigate = useNavigate();
  const [dt, setDt] = React.useState(new Date());
  const isDesktop = useResponsive("up", "xs");
  const mid = window.localStorage.getItem("manager_uid");

  useEffect(() => {
    if (mid) {
      getManager(mid);
    }
  }, [mid]);

  const getManager = (managerId) => {
    axios
      .get(`managers/get/uId/${managerId}`)
      .then((res) => {
        const result = res.data.result;
        setManager(result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  React.useEffect(() => {
    let timer = setInterval(() => setDt(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });
  const handleGoBack = () => {
    localStorage.removeItem("manager_id");
    localStorage.removeItem("manager_city");
    navigate("/dashboard");
  };
  return (
    <RootStyle
      className="topHeader"
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: "100% !important",
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}
        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <img src={pn} alt="jhdj" style={{ height: "35px" }} />{" "}
        <Box sx={{ m: 2 }}>
          <Chip label={`V-${process.env.REACT_APP_VERSION }`} size="small" color="primary" />
        </Box>
            

        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="right"
          spacing={{ xs: 0, sm: 0 }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            align="right"
            sx={{
              color: "black",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: 1.2,
            }}
          >
            {dt.toLocaleTimeString(navigator.language, {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Typography>
          <Typography
            sx={{
              color: "black",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: 1.2,
            }}
          >
            {managerId?.length > 0 &&
              getLoginUser?.role === "ADMIN_ROLE" &&
              manager?.data?.cityObj?.city}

            {managerId?.length > 0 &&
              getLoginUser?.role === "MANAGER_ROLE" &&
              getLoginUser?.data?.cityObj?.city}
            {managerId == null &&
              getLoginUser?.role === "ADMIN_ROLE" &&
              `${getLoginUser?.data?.name} (Admin)`}
          </Typography>
        </Stack>
        {managerId?.length > 0 && getLoginUser?.role === "ADMIN_ROLE" ? (
          <Tooltip title="Go back" placement="bottom" sx={{ zIndex: 10001 }}>
            <KeyboardReturnIcon
              sx={{
                width: "40px",
                height: "40px",
                color: "#000000",
                marginLeft: "20px",
                cursor: "pointer",
              }}
              onClick={handleGoBack}
            />
          </Tooltip>
        ) : (
          <NavbarDocs />
        )}
      </Toolbar>
    </RootStyle>
  );
}
