import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
} from "@mui/material";
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import axios from "../../utils/axiosconfig";
import {
  UserListHead,
  UserListToolbar,
} from "../../sections/@dashboard/user/list";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "src/components/LoadingScreen";
import axiosOrg from "axios";
import { fetchRates, reset } from "src/redux/slices/GetRates";
import ApartmentIcon from "@mui/icons-material/Apartment";

const TABLE_HEAD = [
  { id: "city", label: "City", alignRight: false },
  { id: "baseKm", label: "Base Km", alignRight: true },
  {
    id: "basicWaitingDuration",
    label: "Basic Waiting Duration",
    alignRight: true,
  },
  { id: "waitingRate", label: "Waiting Rate", alignRight: true },
  { id: "distanceRate", label: "Distance Rate", alignRight: true },
  { id: "distancePay", label: "Distance Pay", alignRight: true },
  { id: "durationRate", label: "Duration Rate", alignRight: true },
  { id: "durationPay", label: "Duration Pay", alignRight: true },
  {
    id: "fragilePerPointRate",
    label: "Fragile/Point Rate",
    alignRight: true,
  },
  {
    id: "nonFragilePerPointRate",
    label: "Non-Fragile/Point Rate",
    alignRight: true,
  },
  {
    id: "fragilePerPointPay",
    label: "Fragile/Point Pay",
    alignRight: true,
  },
  {
    id: "nonFragilePerPointPay",
    label: "Non-Fragile/Point Pay",
    alignRight: true,
  },
];
export default function Rates() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.getRates);
  const [rates, setRates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filterData, setFilterData] = useState([]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = rates?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(1);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = rates?.filter((user) => !selected.includes(user.name));
    setSelected([]);
    setRates(deleteUsers);
  };

  const isNotFound = rates?.length === 0 ? true : false;
  useEffect(() => {
    dispatch(reset([]));
  }, [dispatch]);

  useEffect(() => {
    const source = axiosOrg.CancelToken.source();
    getRates();
    return () => {
      source.cancel("Request canceled by cleanup");
    };
  }, [state?.data]);

  useEffect(() => {
    if (filterValue?.length > 0) {
      const timeoutId = setTimeout(() => {
        filterSearch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValue]);

  useEffect(() => {
    filterSearch();
  }, [rowsPerPage, page]);

  const filterSearch = async () => {
    if (state.data.length !== 0 && !initialLoad) {
      setLoading(true);
      const payload = {};
      payload.cityId = localStorage.getItem("manager_city");
      payload.type = "";
      payload.filter = filterValue;
      payload.search = searchValue;
      payload.page = page;
      payload.limit = rowsPerPage;
      console.log("all bookings payload........", payload.search);
      await axios
        .post("/rates/get/all", payload)
        .then((res) => {
          console.log("from search..........", payload.search);
          setLoading(false);
          setRates(res?.data?.result || []);
          setTotalItems(res?.data?.pagination?.totalItems || 0);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getRates = () => {
    try {
      if (state.data.length === 0) {
        const payload = {};
        payload.type = "all";
        payload.filter = "";
        payload.search = "";
        payload.page = page;
        payload.limit = rowsPerPage;
        dispatch(fetchRates(payload));
      } else {
        setRates(state?.data?.data?.result || []);
        setTotalItems(state?.data?.data?.pagination?.totalItems || 0);
        setFilterData(state?.data?.data?.filters || []);
        setInitialLoad(false);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <ThemeProvider theme={{}}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1000,
          marginTop: 200,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <CircularProgress />}
      </div>
      {state?.isLoading && initialLoad && <LoadingScreen />}
      <Page title="Bookings">
        <Box sx={{ width: "100%" }}>
          <Card sx={{ borderRadius: "0" }}>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDeleteUsers={() => handleDeleteMultiUser(selected)}
              setFilterValue={setFilterValue}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              filterData={filterData}
              isMonthRequired={true}
            />

            <TableContainer sx={{ minWidth: 800, height: "calc(80vh)" }}>
              <Scrollbar>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={rates?.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {rates?.map((row, index) => {
                      const isItemSelected = selected.indexOf(row.id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          sx={{ textDecoration: "none" }}
                        >
                          <TableCell
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <ApartmentIcon sx={{ mr: 2, fontSize: "30px" }} />
                            {row?.cityObj?.city || " "}
                          </TableCell>
                          <TableCell align="center">{row?.baseKm} km</TableCell>
                          <TableCell align="center">
                            {row?.basicWaitingDuration} min
                          </TableCell>
                          <TableCell align="center">
                            ₹{row?.waitingRate}
                          </TableCell>
                          <TableCell align="center">
                            ₹{row?.distanceRate}
                          </TableCell>
                          <TableCell align="center">
                            ₹{row?.distancePay}
                          </TableCell>
                          <TableCell align="center">
                            ₹{row?.durationRate}
                          </TableCell>
                          <TableCell align="center">
                            ₹{row?.durationPay}
                          </TableCell>
                          <TableCell align="center">
                            ₹{row?.fragilePerPointRate}
                          </TableCell>
                          <TableCell align="center">
                            ₹{row?.nonFragilePerPointRate}
                          </TableCell>
                          <TableCell align="center">
                            ₹{row?.fragilePerPointPay}
                          </TableCell>
                          <TableCell align="center">
                            ₹{row?.nonFragilePerPointPay}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {isNotFound && (
                      <TableRow>
                        <TableCell align="center" sx={{ py: 3 }} colSpan={15}>
                          No data found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[50, 100, 250]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={(e, page) => setPage(page + 1)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>
      </Page>
    </ThemeProvider>
  );
}
